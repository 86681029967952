import { createStore } from 'satcheljs';
import type { CachedCoachState } from './types/CachedCoachState';

interface DraftCopilotStore {
    copilotSeen: Map<string, string[]>;
    cachedCoachState: Map<string, CachedCoachState>;
}

const initialStore: DraftCopilotStore = {
    copilotSeen: new Map<string, string[]>(),
    cachedCoachState: new Map<string, CachedCoachState>(),
};

const store = createStore<DraftCopilotStore>('DraftCopilotStore', initialStore)();
export const getDraftCopilotStore = () => store;
