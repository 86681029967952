import addInfoBarMessage from 'owa-info-bar/lib/actions/addInfoBarMessage';
import removeInfoBarMessage from 'owa-info-bar/lib/actions/removeInfoBarMessage';
import setIsFromValid from '../actions/setIsFromValid';
import type { ComposeViewState, FromViewState } from 'owa-mail-compose-store';

export const INVALID_SENDER_ERROR = 'errorMessageInvalidSenderMailCanNotBeSend';

export default function handleIsFromValid(
    composeViewState: ComposeViewState,
    fromViewState: FromViewState,
    isFromValid: boolean
) {
    setIsFromValid(fromViewState, isFromValid);

    if (isFromValid) {
        removeInfoBarMessage(composeViewState, INVALID_SENDER_ERROR);
    } else {
        addInfoBarMessage(composeViewState, INVALID_SENDER_ERROR);
    }
}
