import type ExpressionPickerViewState from './schema/ExpressionPickerViewState';
import type ExpressionStore from './schema/ExpressionStore';
import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

const expressionStoreData: ExpressionStore = {
    expressionPickerViewStates: new ObservableMap<string, ExpressionPickerViewState>(),
    selectedSkinTone: '',
    useFlexPane: false,
    isSxSDisplayed: false,
    primaryExpressionId: '',
};

const store = createStore<ExpressionStore>('expression', expressionStoreData)();
export default store;
export const getStore = () => store;
