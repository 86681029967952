import type { FeedbackManagerState } from 'owa-feedback-manager/lib/store/schema/FeedbackManagerState';
import type RecipientWellWithFindControlViewState from 'owa-recipient-types/lib/types/RecipientWellWithFindControlViewState';
import type SubstrateSearchScenario from 'owa-search-service/lib/data/schema/SubstrateSearchScenario';
import createDropViewState from 'owa-dnd/lib/utils/createDropViewState';
import {
    FindResultType,
    DirectorySearchType,
} from 'owa-recipient-types/lib/types/FindControlViewState';
import createRecipientEditorViewState from 'owa-recipient-create-viewstate/lib/util/createRecipientEditorViewState';
import type { MailboxInfo } from 'owa-client-types';

let globalRecipientWellId = 0;

export default function createRecipientWell(
    mailboxInfo: MailboxInfo,
    findPeopleFeedbackManager?: FeedbackManagerState,
    searchScenario?: SubstrateSearchScenario,
    postMessageToHostOnComposeDirty?: boolean,
    focusOnInit?: boolean
): RecipientWellWithFindControlViewState {
    return {
        mailboxInfo,
        recipientWellId: generateRecipientWellId(),
        queryString: '',
        recipients: [],
        isDirty: false,
        shouldShowContactPicker: false,
        findResultSet: [],
        findResultType: FindResultType.None,
        isSearching: false,
        directorySearchType: DirectorySearchType.None,
        dropViewState: createDropViewState(),
        findPeopleFeedbackManager,
        numberOfCacheResults: 0,
        recipientEditorViewState: createRecipientEditorViewState(
            mailboxInfo,
            findPeopleFeedbackManager,
            searchScenario,
            focusOnInit
        ),
        searchScenario,
        postMessageToHostOnComposeDirty,
    };
}

export function generateRecipientWellId(): string {
    return `rw-${++globalRecipientWellId}`;
}
