import type { LazyModule } from 'owa-bundling';
import { LazyImport } from 'owa-bundling';
import type { CoreOption, LazyOption } from '../store/schema/Option';
import type OptionComponentSettings from '../store/schema/OptionComponentSettings';
import type {
    default as OptionQuickComponentSettings,
    OptionQuickComponentSettingsWithKey,
} from '../store/schema/OptionQuickComponentSettings';

function createLazyOption<TFullModule, TQuickModule>(
    coreOption: CoreOption,
    fullOptionLazyModule?: LazyModule<TFullModule>,
    fullSettingsGetter?: (m: TFullModule) => OptionComponentSettings,
    quickOptionLazyModule?: LazyModule<TQuickModule>,
    quickOptionsGetter?: (
        m: TQuickModule
    ) => OptionQuickComponentSettings | OptionQuickComponentSettingsWithKey[]
): LazyOption {
    const fullLazyImport =
        fullOptionLazyModule && fullSettingsGetter
            ? new LazyImport(fullOptionLazyModule, fullSettingsGetter)
            : null;
    const quickLazyImport =
        quickOptionLazyModule && quickOptionsGetter
            ? new LazyImport(quickOptionLazyModule, quickOptionsGetter)
            : null;
    return {
        ...coreOption,
        tryImportFullOptionFormSettingsSync: fullLazyImport
            ? () => fullLazyImport.tryImportForRender()
            : undefined,
        tryImportQuickOptionSettingsSync: quickLazyImport
            ? () => quickLazyImport.tryImportForRender()
            : undefined,
    };
}

export { createLazyOption };
