import { mutatorAction } from 'satcheljs';
import { getDraftCopilotStore } from '../store/store';
import type { CachedCoachState } from '../store/types/CachedCoachState';

export const setCachedCoachState = mutatorAction(
    'setCachedCoachResponse',
    (composeId: string, response: CachedCoachState | undefined) => {
        const store = getDraftCopilotStore();
        if (response) {
            store.cachedCoachState.set(composeId, response);
        } else {
            store.cachedCoachState.delete(composeId);
        }
    }
);
