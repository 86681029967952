import type { default as Option, CoreOption } from '../store/schema/Option';
import type OptionComponentSettings from '../store/schema/OptionComponentSettings';
import type {
    default as OptionQuickComponentSettings,
    OptionQuickComponentSettingsWithKey,
} from '../store/schema/OptionQuickComponentSettings';

export function createOption(
    coreOption: CoreOption,
    fullOptionFormSettings?: OptionComponentSettings,
    quickOptionSettings?: OptionQuickComponentSettings | OptionQuickComponentSettingsWithKey[]
): Option {
    return { ...coreOption, fullOptionFormSettings, quickOptionSettings };
}
