import type RecipientEditorViewState from 'owa-recipient-types/lib/types/RecipientEditorViewState';
import createRecipientPickerPluginViewState from './createRecipientPickerPluginViewState';
import type { FeedbackManagerState } from 'owa-feedback-manager/lib/store/schema/FeedbackManagerState';
import type SubstrateSearchScenario from 'owa-search-service/lib/data/schema/SubstrateSearchScenario';
import type { MailboxInfo } from 'owa-client-types';

let nextRecipientEditorId = 0;

function getNextRecipientEditorId(): string {
    return (nextRecipientEditorId++).toString();
}

export default function createRecipientEditorViewState(
    mailboxInfo: MailboxInfo,
    findPeopleFeedbackManager?: FeedbackManagerState,
    searchScenario?: SubstrateSearchScenario,
    focusOnInit?: boolean
): RecipientEditorViewState {
    return {
        editorId: getNextRecipientEditorId(),
        ...createRecipientPickerPluginViewState(
            mailboxInfo,
            findPeopleFeedbackManager,
            searchScenario
        ),
        recipientsChangedStamp: 0,
        focusOnInit: !!focusOnInit,
    };
}
