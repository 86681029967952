import { isFeatureEnabled } from 'owa-feature-flags';
import { logCoreUsage } from 'owa-analytics';

interface MailComposeInfo {
    [key: string]: string | boolean | undefined | number;
}

/**
 * Function to store the log mail compose send
 * @param editorId the editor id of the compose form
 * @param mailComposeInfo the mail compose info to log
 * @param eventName the event name to log
 */

export default function logMailComposeSaveSend(
    editorId: string,
    mailComposeInfo: MailComposeInfo,
    eventName: string
) {
    if (isFeatureEnabled('cmp-compose-send-logs')) {
        logCoreUsage('MailComposeSaveSendLog', {
            editorId,
            eventName,
            ...mailComposeInfo,
        });
    }
}
