import switchToReadWriteRecipient from './switchToReadWriteRecipient';
import type { ComposeViewState } from 'owa-mail-compose-store';
import createFromRecipientWell from '../utils/createFromRecipientWell';
import removeInfoBarMessage from 'owa-info-bar/lib/actions/removeInfoBarMessage';
import { INVALID_SENDER_ERROR } from '../utils/handleIsFromValid';
import { addDatapointConfig } from 'owa-analytics-actions';
import { action, mutator, orchestrator } from 'satcheljs';

const toggleFrom = action('toggleFrom', (viewState: ComposeViewState) =>
    addDatapointConfig(
        {
            name: 'ComposeCommandShowFrom',
        },
        { viewState }
    )
);

mutator(toggleFrom, ({ viewState }) => {
    viewState.fromViewState.isFromShown = !viewState.fromViewState.isFromShown;

    if (viewState.fromViewState.isFromShown && !viewState.fromViewState.from) {
        viewState.fromViewState.from = createFromRecipientWell(
            viewState.mailboxInfo,
            undefined,
            // New error uncovered due to fix in "addDatapointConfig" typings
            //  -> TS2345: Argument of type 'ClientItemId | null' is not assignable to parameter of type 'ItemId | undefined'.
            // @ts-expect-error
            viewState.referenceItemId
        );
    }
});

orchestrator(toggleFrom, ({ viewState }) => {
    if (!viewState.fromViewState.isFromShown) {
        removeInfoBarMessage(viewState, INVALID_SENDER_ERROR);
    }

    // Readonly recipient well should be changed to readwrite recipient well when show from.
    if (viewState.fromViewState.isFromShown && viewState.showCompactRecipientWell) {
        switchToReadWriteRecipient(viewState);
    }
});

export default toggleFrom;
