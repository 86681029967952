import type { RecipientPickerPluginViewState } from 'owa-recipient-types/lib/types/RecipientPickerPluginViewState';
import {
    FindResultType,
    DirectorySearchType,
} from 'owa-recipient-types/lib/types/FindControlViewState';
import createPickerPluginViewState from 'owa-editor-picker-plugin/lib/utils/createPickerPluginViewState';
import type { FeedbackManagerState } from 'owa-feedback-manager/lib/store/schema/FeedbackManagerState';
import type SubstrateSearchScenario from 'owa-search-service/lib/data/schema/SubstrateSearchScenario';
import type { MailboxInfo } from 'owa-client-types';

export default function createRecipientPickerPluginViewState(
    mailboxInfo: MailboxInfo,
    findPeopleFeedbackManager?: FeedbackManagerState,
    searchScenario?: SubstrateSearchScenario
): RecipientPickerPluginViewState {
    return {
        ...createPickerPluginViewState(),
        mailboxInfo,
        queryString: '',
        findResultSet: [],
        isSearching: false,
        directorySearchType: DirectorySearchType.None,
        selectedRecipientIndex: -1,
        selectedHeaderIndex: -1,
        selectedFooterIndex: -1,
        findResultType: FindResultType.None,
        findPeopleFeedbackManager,
        currentRenderedQueryString: '',
        numberOfCacheResults: 0,
        searchScenario,
    };
}
