import { type DrawEffectPattern } from '../enums/DrawEffectPattern';
import type { PenAttributes } from '../interfaces/PenAttributes';

export const DRAWING_CANVAS_PROPERTY_NAME = 'DrawingCanvasElements';
export const DEFAULT_LASSO_CURSOR = 'crosshair';
export const ANALYTICS_TOOL_NAME = 'DrawTool';
export const ANALYTICS_CANVAS_COUNT_NAME = 'DrawCanvasCount';
export const ANALYTICS_EVENT_NAME = 'DrawCanvas';

interface DrawCanvasDivAttributes {
    maxWidth: number;
    contentEditable: boolean;
    tabIndex: number;
}

interface PenCursorAttributes {
    defaultCursorOutlineColor: string;
    minCursorRadius: number;
    defaultCursorOutlineWidth: number;
}

interface HighlighterCursorAttributes {
    defaultCursorOutlineColor: string;
    minCursorLength: number;
    defaultCursorOutlineWidth: number;
    nibAspectRatio: number;
}

interface EraserCursorAttributes {
    defaultCursorSize: number;
    defaultCursorColor: string;
    defaultCursorEraserOutlineWidth: number;
}

export const DRAW_CANVAS_DIV_ATTRIBUTES: DrawCanvasDivAttributes = Object.freeze({
    maxWidth: 1318, // in px
    contentEditable: false,
    tabIndex: 0,
});

export const HIGHLIGHTER_DEFAULT_ATTRIBUTES: PenAttributes = Object.freeze({
    thickness: 3,
    color: '#FFFC00',
});

export const EFFECT_PEN_DEFAULT_ATTRIBUTES: PenAttributes = Object.freeze({
    thickness: 3,
    color: '#AE198D',
    effectPattern: 'galaxy',
});

export const BLACK_PEN_DEFAULT_ATTRIBUTES: PenAttributes = Object.freeze({
    thickness: 3,
    color: '#000000',
});

export const RED_PEN_DEFAULT_ATTRIBUTES: PenAttributes = Object.freeze({
    thickness: 3,
    color: '#FF0000',
});

export const ERASER_CURSOR_ATTRIBUTES: EraserCursorAttributes = Object.freeze({
    defaultCursorSize: 28,
    defaultCursorColor: '#666666eb',
    defaultCursorEraserOutlineWidth: 2,
});

export const PEN_CURSOR_ATTRIBUTES: PenCursorAttributes = Object.freeze({
    defaultCursorOutlineColor: 'white',
    minCursorRadius: 4,
    defaultCursorOutlineWidth: 2,
});

export const HIGHLIGHTER_CURSOR_ATTRIBUTES: HighlighterCursorAttributes = Object.freeze({
    defaultCursorOutlineColor: 'white',
    minCursorLength: 4,
    defaultCursorOutlineWidth: 2,
    nibAspectRatio: 2,
});

export const CANVAS_RESIZING_ATTRIBUTES = Object.freeze({
    inertiaOfResizing: 4,
    minimumCanvasHeight: 100,
    minimumCanvasWidth: 100,
});
