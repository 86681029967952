import type { MailboxInfo } from 'owa-client-types';
import type UnifiedGroupDetails from 'owa-service/lib/contract/UnifiedGroupDetails';
import { getGroupInformation } from './getGroupInformation';

export default function getGroupDetails(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): UnifiedGroupDetails | undefined {
    const group = getGroupInformation(mailboxInfo, groupSmtpAddress.toLowerCase());
    return group?.groupDetails;
}
