import { createLazyComponent, LazyAction, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Protection"*/ './lazyIndex'), {
    name: 'Protection',
});

export const lazyLoadCLPUserLabels = new LazyAction(lazyModule, m => m.loadCLPUserLabels);
export const lazyGetPendingLoadLabelPromise = new LazyAction(
    lazyModule,
    m => m.getPendingLoadLabelPromise
);
export const lazyShowJustificationModal = new LazyAction(lazyModule, m => m.showJustificationModal);
export const lazyLoadItemCLPInfo = new LazyAction(lazyModule, m => m.loadItemCLPInfo);
export const lazyUpdateItemCLPInfo = new LazyImport(lazyModule, m => m.updateItemCLPInfo);
export const lazyLogReadCLPLabel = new LazyImport(lazyModule, m => m.logReadCLPLabel);
export const lazyLogCLPLabelChange = new LazyAction(lazyModule, m => m.logCLPLabelChange);
export const lazyTriggerCLPAutoLabeling = new LazyImport(lazyModule, m => m.triggerCLPAutoLabeling);
export const lazyShouldShowLouderLabelsMenu = new LazyImport(
    lazyModule,
    m => m.shouldShowLouderLabelsMenu
);
export const lazyLoadComplianceConfig = new LazyAction(lazyModule, m => m.loadComplianceConfig);
export const CLPSubjectHeaderLabel = createLazyComponent(lazyModule, m => m.CLPSubjectHeaderLabel);
export const CLPMandatoryLabelModal = createLazyComponent(
    lazyModule,
    m => m.CLPMandatoryLabelModal
);
export const lazyLoadDLPPolicies = new LazyAction(lazyModule, m => m.loadDLPPolicies);
export const lazyLoadDLPPoliciesForSecondaryAccounts = new LazyAction(
    lazyModule,
    m => m.loadDLPPoliciesForSecondaryAccounts
);
export const lazyHandleAutoLabelingResult = new LazyAction(
    lazyModule,
    m => m.handleAutoLabelingResult
);
