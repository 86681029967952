import type { FontPickerItem } from './FontPickerItem';
import { APTOS_FONT_NAME_LIST } from 'owa-aptos/lib/utils/aptosFonts';

export interface FontName {
    name: string;
    family: string;
    localizedName?: string;
}

// When localized name is missing, use the name
export function getLocalizedFontName(fontName: FontName): string {
    return fontName.localizedName ?? fontName.name;
}

export const FONT_NAME_LIST: FontName[] = [
    ...APTOS_FONT_NAME_LIST,
    { name: 'Arial', family: 'Arial,Helvetica,sans-serif' },
    { name: 'Arial Black', family: "'Arial Black',Arial,sans-serif" },
    { name: 'Calibri', family: 'Calibri,Helvetica,sans-serif' },
    { name: 'Calibri Light', family: "'Calibri Light','Helvetica Light',sans-serif" },
    { name: 'Cambria', family: 'Cambria,Georgia,serif' },
    { name: 'Candara', family: 'Candara,Optima,sans-serif' },
    { name: 'Century Gothic', family: "'Century Gothic',sans-serif" },
    { name: 'Comic Sans MS', family: "'Comic Sans MS',Chalkboard,cursive" },
    { name: 'Consolas', family: 'Consolas,Courier,monospace' },
    { name: 'Constantia', family: "Constantia,'Hoefler Text',serif" },
    { name: 'Corbel', family: 'Corbel,Skia,sans-serif' },
    { name: 'Courier New', family: "'Courier New',monospace" },
    {
        name: 'Franklin Gothic Book',
        family: "'Franklin Gothic Book','Avenir Next Condensed',sans-serif",
    },
    {
        name: 'Franklin Gothic Demi',
        family: "'Franklin Gothic Demi','Avenir Next Condensed Demi Bold',sans-serif",
    },
    {
        name: 'Franklin Gothic Medium',
        family: "'Franklin Gothic Medium','Avenir Next Condensed Medium',sans-serif",
    },
    { name: 'Garamond', family: 'Garamond,Georgia,serif' },
    { name: 'Georgia', family: 'Georgia,serif' },
    { name: 'Impact', family: 'Impact,Charcoal,sans-serif' },
    { name: 'Lucida Console', family: "'Lucida Console',Monaco,monospace" },
    { name: 'Lucida Handwriting', family: "'Lucida Handwriting','Apple Chancery',cursive" },
    { name: 'Lucida Sans Unicode', family: "'Lucida Sans Unicode','Lucida Grande',sans-serif" },
    { name: 'Palatino Linotype', family: "'Palatino Linotype','Book Antiqua',Palatino,serif" },
    {
        name: 'Segoe UI',
        family: "'Segoe UI', 'Segoe UI Web (West European)', 'Helvetica Neue', sans-serif",
    },
    { name: 'Sitka Heading', family: "'Sitka Heading',Cochin,serif" },
    { name: 'Sitka Text', family: "'Sitka Text',Cochin,serif" },
    { name: 'Tahoma', family: 'Tahoma,Geneva,sans-serif' },
    { name: 'Times', family: "Times,'Times New Roman',serif" },
    { name: 'Times New Roman', family: "'Times New Roman',Times,serif" },
    { name: 'Trebuchet MS', family: "'Trebuchet MS',Trebuchet,sans-serif" },
    { name: 'TW Cen MT', family: "'TW Cen MT','Century Gothic',sans-serif" },
    { name: 'Verdana', family: 'Verdana,Geneva,sans-serif' },
    { name: '-', family: '-' }, //divider between fonts for different scripts (order is by style)
    {
        name: 'Microsoft YaHei',
        family: "'Microsoft YaHei','\u5FAE\u8F6F\u96C5\u9ED1',STHeiti,sans-serif",
        localizedName: '\u5FAE\u8F6F\u96C5\u9ED1',
    }, //chineseS Microsoft recommended font
    {
        name: 'SimHei',
        family: "SimHei,'\u9ED1\u4F53',STHeiti,sans-serif",
        localizedName: '\u9ED1\u4F53',
    }, //chineseS
    {
        name: 'NSimSun',
        family: "NSimSun,'\u65B0\u5B8B\u4F53',SimSun,'\u5B8B\u4F53',SimSun-ExtB,'\u5B8B\u4F53-ExtB',STSong,serif",
        localizedName: '\u65B0\u5B8B\u4F53',
    }, //chineseS
    {
        name: 'FangSong',
        family: "FangSong,'\u4EFF\u5B8B',STFangsong,serif",
        localizedName: '\u4EFF\u5B8B',
    }, //chineseS
    {
        name: 'SimLi',
        family: "SimLi,'\u96B6\u4E66','Baoli SC',serif",
        localizedName: '\u96B6\u4E66',
    }, //chineseS
    { name: 'KaiTi', family: "KaiTi,'\u6977\u4F53',STKaiti,serif", localizedName: '\u6977\u4F53' }, //chineseS
    { name: '-', family: '-' }, //divider between fonts for different scripts (order is by style)
    {
        name: 'Microsoft JhengHei',
        family: "'Microsoft JhengHei','\u5FAE\u8EDF\u6B63\u9ED1\u9AD4','Apple LiGothic',sans-serif",
        localizedName: '\u5FAE\u8EDF\u6B63\u9ED1\u9AD4',
    }, //chineseT Microsoft recommended font
    {
        name: 'PMingLiU',
        family: "PMingLiU,'\u65B0\u7D30\u660E\u9AD4',PMingLiU-ExtB,'\u65B0\u7D30\u660E\u9AD4-ExtB','Apple LiSung',serif",
        localizedName: '\u65B0\u7D30\u660E\u9AD4',
    }, //chineseT
    {
        name: 'DFKai-SB',
        family: "DFKai-SB,'\u6A19\u6977\u9AD4','BiauKai',serif",
        localizedName: '\u6A19\u6977\u9AD4',
    }, //chineseT
    { name: '-', family: '-' }, //divider between fonts for different scripts (order is alphabetical by foundry)
    {
        name: 'Meiryo',
        family: "Meiryo,'\u30E1\u30A4\u30EA\u30AA','Hiragino Sans',sans-serif",
        localizedName: '\u30E1\u30A4\u30EA\u30AA',
    }, //japanese
    {
        name: 'MS PGothic',
        family: "'MS PGothic','\uFF2D\uFF33 \uFF30\u30B4\u30B7\u30C3\u30AF','MS Gothic','\uFF2D\uFF33 \u30B4\u30B7\u30C3\u30AF','Hiragino Kaku Gothic ProN',sans-serif",
        localizedName: '\uFF2D\uFF33 \uFF30\u30B4\u30B7\u30C3\u30AF',
    }, //japanese
    {
        name: 'MS PMincho',
        family: "'MS PMincho','\uFF2D\uFF33 \uFF30\u660E\u671D','MS Mincho','\uFF2D\uFF33 \u660E\u671D','Hiragino Mincho ProN',serif",
        localizedName: '\uFF2D\uFF33 \uFF30\u660E\u671D',
    }, //japanese
    {
        name: 'Yu Gothic',
        family: "'Yu Gothic','\u6E38\u30B4\u30B7\u30C3\u30AF','YuGothic',sans-serif",
        localizedName: '\u6E38\u30B4\u30B7\u30C3\u30AF',
    }, //japanese
    {
        name: 'Yu Mincho',
        family: "'Yu Mincho','\u6E38\u660E\u671D','YuMincho',serif",
        localizedName: '\u6E38\u660E\u671D',
    }, //japanese
    { name: '-', family: '-' }, //divider between fonts for different scripts (order is for legacy reasons)
    {
        name: 'Malgun Gothic',
        family: "'Malgun Gothic','\uB9D1\uC740 \uACE0\uB515',AppleGothic,sans-serif",
        localizedName: '\uB9D1\uC740 \uACE0\uB515',
    }, //korean Microsoft recommended font
    {
        name: 'Gulim',
        family: "Gulim,'\uAD74\uB9BC','Nanum Gothic',sans-serif",
        localizedName: '\uAD74\uB9BC',
    }, //korean
    {
        name: 'Dotum',
        family: "Dotum,'\uB3CB\uC6C0',AppleGothic,sans-serif",
        localizedName: '\uB3CB\uC6C0',
    }, //korean
    {
        name: 'Batang',
        family: "Batang,'\uBC14\uD0D5',AppleMyungjo,serif",
        localizedName: '\uBC14\uD0D5',
    }, //korean
    {
        name: 'BatangChe',
        family: "BatangChe,'\uBC14\uD0D5\uCCB4',AppleMyungjo,serif",
        localizedName: '\uBC14\uD0D5\uCCB4',
    }, //korean
    {
        name: 'Gungsuh',
        family: "Gungsuh,'\uAD81\uC11C',GungSeo,serif",
        localizedName: '\uAD81\uC11C',
    }, //korean
    { name: '-', family: '-' }, //divider between fonts for different scripts (order is alphabetical)
    { name: 'Leelawadee UI', family: "'Leelawadee UI',Thonburi,sans-serif" }, //thai Microsoft recommended font
    { name: 'Angsana New', family: "'Angsana New','Leelawadee UI',Sathu,serif" }, //thai
    { name: 'Cordia New', family: "'Cordia New','Leelawadee UI',Silom,sans-serif" }, //thai
    { name: 'DaunPenh', family: "DaunPenh,'Leelawadee UI','Khmer MN',sans-serif" }, //khmer
    { name: '-', family: '-' }, //divider between fonts for different scripts (order is alphabetical)
    { name: 'Nirmala UI', family: "'Nirmala UI',sans-serif" }, //indic Microsoft recommended font
    { name: 'Gautami', family: "Gautami,'Nirmala UI','Telugu MN',sans-serif" }, //indic
    { name: 'Iskoola Pota', family: "'Iskoola Pota','Nirmala UI','Sinhala MN',sans-serif" }, //indic
    { name: 'Kalinga', family: "Kalinga,'Nirmala UI','Oriya MN',sans-serif" }, //indic
    { name: 'Kartika', family: "Kartika,'Nirmala UI','Malayalam MN',sans-serif" }, //indic
    { name: 'Latha', family: "Latha,'Nirmala UI','Tamil MN',sans-serif" }, //indic
    { name: 'Mangal', family: "Mangal,'Nirmala UI','Devanagari Sangam MN',sans-serif" }, //indic
    { name: 'Raavi', family: "Raavi,'Nirmala UI','Gurmukhi MN',sans-serif" }, //indic
    { name: 'Shruti', family: "Shruti,'Nirmala UI','Gujarati Sangam MN',sans-serif" }, //indic
    { name: 'Tunga', family: "Tunga,'Nirmala UI','Kannada MN',sans-serif" }, //indic
    { name: 'Vrinda', family: "Vrinda,'Nirmala UI','Bangla MN',sans-serif" }, //indic
    { name: '-', family: '-' }, //divider between fonts for different scripts
    { name: 'Nyala', family: 'Nyala,Kefa,sans-serif' }, //other-ethiopic
    { name: 'Sylfaen', family: 'Sylfaen,Mshtakan,Menlo,serif' }, //other-armenian-georgian
];

export function getFontNameMenuItems(fontlist?: FontName[]): FontPickerItem[] {
    const fontNameList = fontlist ?? FONT_NAME_LIST;
    return fontNameList.map(font => ({
        selectValue: font.name,
        displayValue: getLocalizedFontName(font),
        submitValue: font.family,
    }));
}
