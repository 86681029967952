import type { ComposeViewState } from 'owa-mail-compose-store';
import { mutatorAction } from 'satcheljs';
import type { OwaDate } from 'owa-datetime';

export default mutatorAction(
    'setDeferredSendTime',
    (viewState: ComposeViewState, deferredSendTime: OwaDate | null) => {
        viewState.deferredSendTime = deferredSendTime;
    }
);
