import { mutatorAction } from 'satcheljs';
import { getDraftCopilotStore } from '../store/store';

export const resetDraftCopilotStore = mutatorAction(
    'resetDraftCopilotStore',
    (composeId: string, editorId: string) => {
        const store = getDraftCopilotStore();
        if (store.copilotSeen.has(editorId)) {
            store.copilotSeen.delete(editorId);
        }

        if (store.cachedCoachState.has(composeId)) {
            store.cachedCoachState.delete(composeId);
        }
    }
);
