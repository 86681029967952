import { mutatorAction } from 'satcheljs';
import { getDraftCopilotStore } from '../store/store';

export const setIsCopilotSeen = mutatorAction(
    'setIsCopilotSeen',
    (editorId: string, scenarioKey: string) => {
        if (!getDraftCopilotStore().copilotSeen.has(editorId)) {
            getDraftCopilotStore().copilotSeen.set(editorId, [scenarioKey]);
        } else {
            const prevScenariosSeenForCurrentCompose =
                getDraftCopilotStore().copilotSeen.get(editorId);
            if (prevScenariosSeenForCurrentCompose) {
                getDraftCopilotStore().copilotSeen.set(editorId, [
                    ...prevScenariosSeenForCurrentCompose,
                    scenarioKey,
                ]);
            }
        }
    }
);
