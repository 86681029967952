import type FindRecipientPersonaType from './FindRecipientPersonaType';
import type { PeopleFeedbackState } from './PeopleFeedbackState';
import type { FeedbackManagerState } from 'owa-feedback-manager/lib/store/schema/FeedbackManagerState';
import type SubstrateSearchScenario from 'owa-search-service/lib/data/schema/SubstrateSearchScenario';
import type { MailboxInfo } from 'owa-client-types';

export enum FindResultType {
    None,
    Cache,
    FindPeople,
    SearchDirectory,
    MixedCacheLive,
}

export enum DirectorySearchType {
    None,
    Auto,
    Manual,
}

interface FindControlViewState {
    mailboxInfo: MailboxInfo;
    queryString: string;
    findPeopleFeedbackManager?: FeedbackManagerState;
    findResultSet: FindRecipientPersonaType[];
    findResultType: FindResultType;
    numberOfCacheResults: number;
    isSearching: boolean;
    directorySearchType: DirectorySearchType;
    inForceResolve?: boolean;
    currentRenderedQueryString?: string;
    peopleFeedbackState?: PeopleFeedbackState;
    searchScenario?: SubstrateSearchScenario;
    isForceFeedbackForFPFallback?: boolean;
    filterItem?: (persona: FindRecipientPersonaType) => boolean;
}

export default FindControlViewState;
