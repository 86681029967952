interface FontName {
    name: string;
    family: string;
    localizedName?: string;
}

export const APTOS_FONT_NAME_LIST: FontName[] = [
    {
        name: 'Aptos',
        family: 'Aptos,Aptos_EmbeddedFont,Aptos_MSFontService,Calibri,Helvetica,sans-serif',
    },
    {
        name: 'Aptos Display',
        family: 'Aptos Display,Aptos Display_EmbeddedFont,Aptos Display_MSFontService,Calibri Light,Helvetica Light,sans-serif',
    },
    {
        name: 'Aptos Mono',
        family: 'Aptos Mono,Aptos_EmbeddedFont,Aptos_MSFontService,monospace',
    },
    {
        name: 'Aptos Narrow',
        family: 'Aptos Narrow,Aptos_EmbeddedFont,Aptos_MSFontService,sans-serif',
    },
    {
        name: 'Aptos Serif',
        family: 'Aptos Serif,Aptos_EmbeddedFont,Aptos_MSFontService,serif',
    },
];
