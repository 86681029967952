import { createStore } from 'satcheljs';
import type { FontName } from '../utils/FontName';
import { FONT_NAME_LIST, getFontNameMenuItems } from '../utils/FontName';
import { FONT_SIZE_MENU_ITEMS } from '../utils/FontSize';
import type FontPickerStore from './schema/FontPickerStore';

const getFontPickerStoreData = (fontList: FontName[]): FontPickerStore => {
    return {
        fontName: {
            displayValue: null,
            mru: [],
            items: [...getFontNameMenuItems(fontList)],
        },
        fontSize: {
            displayValue: null,
            mru: [],
            items: [...FONT_SIZE_MENU_ITEMS],
        },
        isLocalFontListLoaded: false,
    };
};

const store = createStore<FontPickerStore>('fontPicker', getFontPickerStoreData(FONT_NAME_LIST))();

export const getStore = () => store;
