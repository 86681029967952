import { LazyImport, LazyModule } from 'owa-bundling';
import type EditorViewState from './store/schema/EditorViewState';

export type { default as PluginWithViewState } from './store/schema/PluginWithViewState';
export type { default as PluginWithUI } from './store/schema/PluginWithUI';
export type { OperateContentType } from './utils/operateContent';
export { getEditorScenarioFromEditorId } from './utils/getEditorScenarioFromEditorId';
const lazyModule = new LazyModule(() => import(/* webpackChunkName: "HtmlEditor" */ './lazyIndex'));

export type { TextDirection } from './store/schema/TextDirection';
export const lazyOperateContent = new LazyImport(lazyModule, m => m.operateContent);
export type { default as PluginViewStateAdapter } from './store/schema/PluginViewStateAdapter';
export type { default as EditorViewState } from './store/schema/EditorViewState';

const lazyFocusToEditor = new LazyImport(lazyModule, m => m.focusToEditor);
export function focusToEditor(viewState: EditorViewState) {
    lazyFocusToEditor.import().then(focusEditor => focusEditor(viewState));
}

export const lazyGetSelectedImage = new LazyImport(lazyModule, m => m.getSelectedImage);

export const lazyConvertInlineCssForHtml = new LazyImport(
    lazyModule,
    m => m.convertInlineCssForHtml
);
